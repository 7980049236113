<template>
  <v-main>
    <v-container>
      <router-view />
      <AnnouncementDialog :announcements="announcements" />
    </v-container>

    <!--    <dashboard-core-footer />-->
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DashboardCoreView",

  components: {
    // DashboardCoreFooter: () => import("./Footer"),
    AnnouncementDialog: () => import("@/views/Announcement/AnnouncementDialog")
  },
  methods: {
    ...mapActions("announcement", ["fetchCurrentAnnouncements"])
  },
  mounted() {
    this.fetchCurrentAnnouncements();
  },
  computed: {
    ...mapGetters("announcement", ["announcements"])
  }
};
</script>
